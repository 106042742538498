.EditProduct {
  background-color: #f9f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.EditProduct .div {
  background-color: #f9f9fc;
  height: 100%;
  position: relative;
  width: 100%;
}

.EditProduct .header {
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #3540520d;
  height: 64px;
  left: 260px;
  position: absolute;
  top: 0;
  width: 1660px;
}

.EditProduct .ic-error-outline {
  height: 24px;
  left: 1432px;
  position: absolute;
  top: 24px;
  width: 24px;
}

.EditProduct .group {
  height: 32px;
  left: 1472px;
  position: absolute;
  top: 16px;
  width: 33px;
}

.EditProduct .overlap-group {
  height: 32px;
  position: relative;
}

.EditProduct .img {
  height: 24px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 24px;
}

.EditProduct .element-status-indicators {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 90px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 14px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
}

.EditProduct .button-text {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.EditProduct .icon {
  height: 32px;
  left: 1521px;
  position: absolute;
  top: 16px;
  width: 35px;
}

.EditProduct .button-text-wrapper {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 90px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 16px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
}

.EditProduct .element-avatars-circle {
  height: 64px;
  left: 1604px;
  position: absolute;
  top: 0;
  width: 40px;
}

.EditProduct .overlap {
  background-color: #f3f7fc;
  border-radius: 20px;
  height: 40px;
  position: relative;
  top: 12px;
}

.EditProduct .text-wrapper {
  color: #4f2564;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 21px;
  left: 13px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.EditProduct .icon-2 {
  height: 24px;
  left: 1572px;
  position: absolute;
  top: 20px;
  width: 24px;
}

.EditProduct .icons {
  height: 24px;
  left: 24px;
  position: absolute;
  top: 20px;
  width: 120px;
}

.EditProduct .text-wrapper-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 40px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.EditProduct .ic-search {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.EditProduct .footer {
  background-color: #ffffff;
  height: 64px;
  left: 260px;
  position: absolute;
  top: 830px;
  width: 79%;
}

.EditProduct .support-help-center {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.EditProduct .dashboard {
  height: 1245px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 260px;
}

.EditProduct .overlap-2 {
  background-color: #4f2564;
  /* height: 100%; */
  height: 1024px;
  left: 0;
  position: absolute;
  top: 64px;
  width: 260px;
}

.EditProduct .element-navigations-menus {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

.EditProduct .element-navigations-menus-2 {
  height: 44px;
  position: relative;
  width: 198px;
}

.EditProduct .text-wrapper-3 {
  color: #eeeeee;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.EditProduct .img-2 {
  height: 20px !important;
  left: 24px !important;
  position: absolute !important;
  top: 12px !important;
  width: 20px !important;
}

.EditProduct .overlap-3 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 88px;
  width: 260px;
}

.EditProduct .element-navigations-menus-wrapper {
  height: 44px;
  position: relative;
  top: -88px;
}

.EditProduct .list-item-wrapper {
  height: 44px;
  position: relative;
  width: 260px;
}

.EditProduct .list-item {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.EditProduct .element-navigations-menus-3 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 198px;
}

.EditProduct .feather-icon-chevron {
  height: 24px;
  left: 214px;
  position: absolute;
  top: 10px;
  width: 24px;
}

.EditProduct .overlap-4 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 44px;
  width: 260px;
}

.EditProduct .element-navigations-menus-4 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 132px;
  width: 260px;
}

.EditProduct .element-navigations-menus-5 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 176px;
  width: 260px;
}

.EditProduct .overlap-group-2 {
  background-color: #7c3a8e;
  height: 44px;
  position: relative;
  width: 260px;
}

.EditProduct .frame-wrapper {
  background-color: #4f2564;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

.EditProduct .frame {
  height: 39px;
  left: 80px;
  position: absolute;
  top: 13px;
  width: 100px;
}

.EditProduct .element-navigations-wrapper {
  height: 21px;
  left: 284px;
  position: absolute;
  top: 15px;
  width: 57px;
}

.EditProduct .element-navigations {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.EditProduct .link {
  color: #0000008a;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08px;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.EditProduct .feather-icon-chevron-2 {
  height: 16px;
  left: 343px;
  position: absolute;
  top: 15px;
  width: 16px;
}

.EditProduct .link-2 {
  color: #0000008a;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 361px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 15px;
  white-space: nowrap;
}

.EditProduct .overlap-5 {
  height: 66px;
  left: 284px;
  position: absolute;
  top: 130px;
  width: 1544px;
}

.EditProduct .group-2 {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 629px;
}

.EditProduct .table-header {
  color: #4f2564;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 379px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.EditProduct .table-header-2 {
  color: #4f2564;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 74px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.EditProduct .div-wrapper {
  align-items: center;
  background-color: #4f2564;
  border-radius: 52px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 485px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
}

/* .EditProduct .glyph-mail-trash {
  height: 36px;
  left: 329px;
  position: absolute;
  top: 15px;
  width: 36px;
} */

.EditProduct .img-3 {
  height: 36px;
  left: 24px;
  position: absolute;
  top: 15px;
  width: 36px;
}

.EditProduct .group-3 {
  height: 66px;
  left: 625px;
  position: absolute;
  top: 0;
  width: 919px;
}

.EditProduct .table-header-3 {
  color: #7c3a8e;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 379px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.EditProduct .element-status-indicators-2 {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 52px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 452px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
}

.EditProduct .overlap-group-wrapper {
  height: 66px;
  left: 610px;
  position: absolute;
  top: 0;
  width: 307px;
}

.EditProduct .overlap-group-3 {
  background-color: #7c3a8e;
  height: 66px;
  position: relative;
  width: 305px;
}

.EditProduct .table-header-4 {
  color: #ffffff;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 74px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.EditProduct .element-status-indicators-3 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 52px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 165px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
  width: 39px;
}

.EditProduct .button-text-2 {
  color: #4f2564;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.EditProduct .rectangle {
  background-color: #7c3a8e;
  height: 4px;
  left: 284px;
  position: absolute;
  top: 84px;
  width: 79%;
}

.EditProduct .frame-2 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 68px;
  left: 284px;
  position: absolute;
  top: 130px;
    width: 79%;
}

.EditProduct .frame-3 {
  background-image: url(../img/rectangle-8.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 283px;
}

.EditProduct .table-header-5 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 6px;
  width: 274px;
}

.EditProduct .feather-icon-chevron-3 {
  height: 24px;
  left: 286px;
  position: absolute;
  top: 6px;
  width: 24px;
}

.EditProduct .element-button-text {
  all: unset;
  align-items: center;
  background-color: #62bb4c;
  border-radius: 4px;
  box-shadow: var(--shadows-contained);
  box-sizing: border-box;
  display: flex;
  gap: 6px;
  height: 36px;
  justify-content: center;
  left: 1487px;
  padding: 6px 15px;
  position: absolute;
  top: 16px;
  width: 109px;
}

.EditProduct .button {
  all: unset;
  box-sizing: border-box;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.EditProduct .element-button-text-2 {
  all: unset;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #7c3a8e;
  border-radius: 4px;
  box-shadow: var(--shadows-contained);
  box-sizing: border-box;
  display: flex;
  gap: 6px;
  height: 36px;
  justify-content: center;
  left: 1362px;
  padding: 6px 15px;
  position: absolute;
  top: 16px;
  width: 109px;
}

.EditProduct .button-text-3 {
  all: unset;
  box-sizing: border-box;
  color: #7c3a8e;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.EditProduct .overlap-wrapper {
  height: 318px;
  left: 284px;
  position: absolute;
  top: 498px;
  width: 79%;
}

.EditProduct .overlap-6 {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 318px;
  position: relative;
  width: 79%;
}

.EditProduct .cards-header {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 52px;
  left: 0;
  position: absolute;
  top: 0;
  width: 79%;
}

.EditProduct .portlet-title {
  color: #000000;
  font-family: var(--h6-heading-font-family);
  font-size: var(--h6-heading-font-size);
  font-style: var(--h6-heading-font-style);
  font-weight: var(--h6-heading-font-weight);
  height: 21px;
  left: 16px;
  letter-spacing: var(--h6-heading-letter-spacing);
  line-height: var(--h6-heading-line-height);
  position: absolute;
  top: 15px;
  width: 654px;
}

.EditProduct .ic-expand-less {
  height: 24px;
  left: 1576px;
  position: absolute;
  top: 13px;
  width: 24px;
}

.EditProduct .table-header-6 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  left: 16px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 355px;
}

.EditProduct .cards-body {
  background-color: #f9f9f9;
  background-image: url(../img/mask-group.png);
  background-size: 100% 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 188px;
  left: 16px;
  position: absolute;
  top: 106px;
  width: 292px;
}

.EditProduct .group-wrapper {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 188px;
  left: 324px;
  position: absolute;
  top: 106px;
  width: 292px;
}

.EditProduct .group-4 {
  height: 76px;
  left: 99px;
  position: absolute;
  top: 51px;
  width: 76px;
}

.EditProduct .group-5 {
  height: 201px;
  left: 284px;
  position: absolute;
  top: 932px;
  width: 1618px;
}

.EditProduct .overlap-7 {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 201px;
  position: relative;
  width: 1616px;
}

.EditProduct .table-header-7 {
  color: var(--text-color00000);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  left: 16px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 355px;
}

.EditProduct .cards-body-2 {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 74px;
  left: 16px;
  position: absolute;
  top: 106px;
  width: 116px;
}

.EditProduct .overlap-group-4 {
  height: 46px;
  position: relative;
  top: 28px;
  width: 117px;
}

.EditProduct .group-6 {
  height: 38px;
  left: 43px;
  position: absolute;
  top: 0;
  width: 31px;
}

.EditProduct .table-header-wrapper {
  background-image: url(../img/vector.png);
  background-size: 100% 100%;
  height: 20px;
  left: 0;
  position: absolute;
  top: 26px;
  width: 117px;
}

.EditProduct .table-header-8 {
  color: #757575;
  font-family: var(--overline-font-family);
  font-size: var(--overline-font-size);
  font-style: var(--overline-font-style);
  font-weight: var(--overline-font-weight);
  height: 14px;
  left: 8px;
  letter-spacing: var(--overline-letter-spacing);
  line-height: var(--overline-line-height);
  position: absolute;
  text-align: center;
  top: 2px;
  white-space: nowrap;
  width: 101px;
}

.EditProduct .img-wrapper {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 74px;
  left: 148px;
  position: absolute;
  top: 106px;
  width: 116px;
}
.EditProduct .div-2 .img-wrapper {
background-color: #4f2564;
    height: 64px;
    left: 0;
    position: absolute;
    top: 0;
    width: 260px;
}

.EditProduct .group-7 {
  height: 26px;
  left: 46px;
  position: absolute;
  top: 24px;
  width: 26px;
}

.EditProduct .group-8 {
  height: 270px;
  left: 284px;
  position: absolute;
  top: 204px;
  width: 79%;
}

.EditProduct .overlap-8 {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 270px;
  position: relative;
  width: 100%;
}

.EditProduct .feather-icon {
  height: 23px;
  left: 632px;
  position: absolute;
  top: 31px;
  width: 24px;
}

.EditProduct .feather-icon-type {
  height: 24px;
  left: 16px;
  position: absolute;
  top: 31px;
  width: 24px;
}

.EditProduct .feather-icon-hash {
  height: 23px;
  left: 635px;
  position: absolute;
  top: 154px;
  width: 24px;
}

.EditProduct .element {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 56px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 31px;
  white-space: nowrap;
  width: 260px;
}

.EditProduct .group-9 {
  height: 36px;
  left: 16px;
  position: absolute;
  top: 71px;
  width: 285px;
}

.EditProduct .overlap-group-5 {
  height: 36px;
  position: relative;
  width: 283px;
}

.EditProduct .element-2 {
  color: #9e9e9e;
  font-family: var(--body1-dedisse-scrips-copy-font-family);
  font-size: var(--body1-dedisse-scrips-copy-font-size);
  font-style: var(--body1-dedisse-scrips-copy-font-style);
  font-weight: var(--body1-dedisse-scrips-copy-font-weight);
  left: 16px;
  letter-spacing: var(--body1-dedisse-scrips-copy-letter-spacing);
  line-height: var(--body1-dedisse-scrips-copy-line-height);
  position: absolute;
  top: 8px;
  width: 216px;
}

.EditProduct .container {
  height: 36px;
  left: 0;
  position: absolute;
  top: 0;
  width: 283px;
}

.EditProduct .group-10 {
  height: 36px;
  left: 326px;
  position: absolute;
  top: 71px;
  width: 285px;
}

.EditProduct .group-11 {
  height: 36px;
  left: 642px;
  position: absolute;
  top: 71px;
  width: 285px;
}

.EditProduct .group-12 {
  height: 36px;
  left: 641px;
  position: absolute;
  top: 194px;
  width: 285px;
}

.EditProduct .group-13 {
  height: 36px;
  left: 329px;
  position: absolute;
  top: 194px;
  width: 285px;
}

.EditProduct .feather-icon-chevron-4 {
  height: 24px;
  left: 252px;
  position: absolute;
  top: 6px;
  width: 24px;
}

.EditProduct .group-14 {
  height: 36px;
  left: 16px;
  position: absolute;
  top: 194px;
  width: 285px;
}

.EditProduct .element-3 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 668px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 31px;
  white-space: nowrap;
  width: 260px;
}

.EditProduct .element-4 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 359px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 31px;
  white-space: nowrap;
  width: 260px;
}

.EditProduct .feather-icon-command {
  height: 24px;
  left: 321px;
  position: absolute;
  top: 34px;
  width: 24px;
}

.EditProduct .element-5 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 665px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 154px;
  white-space: nowrap;
  width: 260px;
}

.EditProduct .element-6 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 56px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 154px;
  white-space: nowrap;
  width: 260px;
}

.EditProduct .element-7 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 356px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 154px;
  white-space: nowrap;
  width: 260px;
}

.EditProduct .feather-icon-type-2 {
  height: 24px;
  left: 16px;
  position: absolute;
  top: 154px;
  width: 24px;
}

.EditProduct .feather-icon-type-3 {
  height: 24px;
  left: 324px;
  position: absolute;
  top: 154px;
  width: 24px;
}

.EditProduct .line {
  height: 98px;
  left: 618px;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 1px;
}

.EditProduct .line-2 {
  height: 98px;
  left: 621px;
  object-fit: cover;
  position: absolute;
  top: 157px;
  width: 1px;
}

.EditProduct .line-3 {
  height: 98px;
  left: 310px;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 1px;
}

.EditProduct .line-4 {
  height: 98px;
  left: 312px;
  object-fit: cover;
  position: absolute;
  top: 157px;
  width: 1px;
}

.EditProduct .line-5 {
  height: 98px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 1px;
}

.EditProduct .line-6 {
  height: 1px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  top: 134px;
  width: 79%;
}

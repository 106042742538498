.errorMsg {
  opacity: 1;
  font-size: 0.7em;
  color: red;
  text-transform: uppercase;
}

.successMsg {
  opacity: 1;
  font-size: 0.7em;
  color: green;
  text-transform: uppercase;
  transition: all 250ms linear;
}

.successMsghide {
  display: none;
  transition: all 250ms linear;
}

.errorMsghide {
  display: none;
  transition: all 250ms linear;
}

.floatingAlerts1 {
  font-size: 16px;
  /* position: absolute; */
  top: 20px;
  left: 20px !important;  
  right: unset !important;
  background: #ececec;
  padding: 5px 30px;
  border-radius: 16px;
  z-index: 10;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.15);
}
.hide{
  display: none;
}

.show{
  display: block;
}
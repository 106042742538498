.frame-wrapper {
  height: 14px;
  position: relative;
  width: 84px;
}

.frame-wrapper .items-per-page {
  color: var(--text-icon-colors5500000);
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.frame {
  height: 14px;
  position: relative;
  width: 14px;
}

.frame .element {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.products1 {
  background-color: #f9f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.products1 .div {
  background-color: #f9f9fc;
  border: 1px none;
  height: 1245px;
  position: relative;
  width: 1920px;
}

.products1 .header {
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #3540520d;
  height: 64px;
  left: 260px;
  position: absolute;
  top: 0;
  width: 1660px;
}

.products1 .ic-error-outline {
  height: 24px;
  left: 1432px;
  position: absolute;
  top: 24px;
  width: 24px;
}

.products1 .group {
  height: 32px;
  left: 1472px;
  position: absolute;
  top: 16px;
  width: 33px;
}

.products1 .overlap-group {
  height: 32px;
  position: relative;
}

.products1 .img {
  height: 24px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 24px;
}

.products1 .element-status-indicators {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 90px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 14px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
}

.products1 .button-text {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.products1 .icon {
  height: 32px;
  left: 1521px;
  position: absolute;
  top: 16px;
  width: 35px;
}

.products1 .button-text-wrapper {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 90px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 16px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
}

.products1 .element-avatars-circle {
  height: 64px;
  left: 1604px;
  position: absolute;
  top: 0;
  width: 40px;
}

.products1 .overlap {
  background-color: #f3f7fc;
  border-radius: 20px;
  height: 40px;
  position: relative;
  top: 12px;
}

.products1 .text-wrapper {
  color: #4f2564;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 21px;
  left: 13px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.products1 .icon-2 {
  height: 24px;
  left: 1572px;
  position: absolute;
  top: 20px;
  width: 24px;
}

.products1 .icons {
  height: 24px;
  left: 24px;
  position: absolute;
  top: 20px;
  width: 120px;
}

.products1 .text-wrapper-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 40px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.products1 .ic-search {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.products1 .footer {
  background-color: #ffffff;
  height: 64px;
  left: 260px;
  mix-blend-mode: normal;
  position: absolute;
  top: 1181px;
  width: 1660px;
}

.products1 .support-help-center {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.products1 .dashboard {
  height: 1245px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 260px;
}

.products1 .overlap-2 {
  background-color: #4f2564;
  height: 8185px;
  left: 0;
  position: absolute;
  top: 64px;
  width: 260px;
}

.products1 .element-navigations-menus {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

.products1 .element-navigations-menus-2 {
  height: 44px;
  position: relative;
  width: 198px;
}

.products1 .text-wrapper-3 {
  color: #eeeeee;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.products1 .img-2 {
  height: 20px !important;
  left: 24px !important;
  position: absolute !important;
  top: 12px !important;
  width: 20px !important;
}

.products1 .overlap-3 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 88px;
  width: 260px;
}

.products1 .element-navigations-menus-wrapper {
  height: 44px;
  position: relative;
  top: -88px;
}

.products1 .list-item-wrapper {
  height: 44px;
  position: relative;
  width: 260px;
}

.products1 .list-item {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.products1 .element-navigations-menus-3 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 198px;
}

.products1 .feather-icon-chevron {
  height: 24px;
  left: 214px;
  position: absolute;
  top: 10px;
  width: 24px;
}

.products1 .overlap-4 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 44px;
  width: 260px;
}

.products1 .element-navigations-menus-4 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 132px;
  width: 260px;
}

.products1 .element-navigations-menus-5 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 176px;
  width: 260px;
}

.products1 .overlap-group-2 {
  background-color: #7c3a8e;
  height: 44px;
  position: relative;
  width: 260px;
}

.products1 .frame-wrapper {
  background-color: #4f2564;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

.products1 .frame {
  height: 39px;
  left: 80px;
  position: absolute;
  top: 13px;
  width: 100px;
}

.products1 .element-navigations-wrapper {
  height: 21px;
    left: 284px;
    position: absolute;
    top: 15px;
    width: 57px;
}

.products1 .element-navigations {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.products1 .link {
  color: #0000008a;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08px;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.products1 .feather-icon-chevron-2 {
  height: 16px;
  left: 79px;
  position: absolute;
  /* top: 90px; */
  width: 16px;  
}

.products1 .link-2 {
  color: #0000008a;
  font-family: "Roboto", Helvetica;
  font-size: 14px;    
  font-weight: 400;
  left: 79px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  /* top: 87px; */
  white-space: nowrap;
}

.products1 .overlap-5 {
  height: 66px;
  left: 284px;
  position: absolute;
  top: 130px;
  width: 1544px;
}

.products1 .group-2 {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 629px;
}

.products1 .table-header {
  color: #4f2564;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 379px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.products1 .table-header-2 {
  color: #4f2564;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 74px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.products1 .div-wrapper {
  align-items: center;
  background-color: #4f2564;
  border-radius: 52px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 485px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
}

/* .products1 .glyph-mail-trash {
  height: 36px;
  left: 329px;
  position: absolute;
  top: 15px;
  width: 36px;
} */

.products1 .img-3 {
  height: 36px;
  left: 24px;
  position: absolute;
  top: 15px;
  width: 36px;  
}

.products1 .group-3 {
  height: 66px;
  left: 625px;
  position: absolute;
  top: 0;
  width: 919px;
}

.products1 .table-header-3 {
  color: #7c3a8e;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 379px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.products1 .element-status-indicators-2 {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 52px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 452px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
}

.products1 .overlap-group-wrapper {
  height: 66px;
  left: 610px;
  position: absolute;
  top: 0;
  width: 307px;
}

.products1 .overlap-group-3 {
  background-color: #7c3a8e;
  height: 66px;
  position: relative;
  width: 305px;
}

.products1 .table-header-4 {
  color: #ffffff;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 74px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

.products1 .element-status-indicators-3 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 52px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 165px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
  width: 39px;
}

.products1 .button-text-2 {
  color: #4f2564;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

/* .products1 .rectangle {
  background-color: #7c3a8e;
  height: 4px;
  left: 284px;
  position: absolute;
  top: 196px;
  width: 1612px;
} */

.products1 .frame-2 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 68px;
  left: 284px;
  position: absolute;
  top: 216px;
  width: 1612px;
}

.products1 .frame_img {
  height: 39px;
  left: 80px;
  position: absolute;
  top: 13px;
  width: 100px;
}

.products1 .frame-3 {
  background-image: url(../img/rectangle-8.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 315px;
}

.products1 .table-header-5 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 6px;
  width: 274px;
}

.products1 .feather-icon-chevron-3 {
  height: 24px;
  left: 286px;
  position: absolute;
  top: 6px;
  width: 24px;
}

.products1 .element-button-text {
  align-items: center;
  background-color: #62bb4c;
  border-radius: 4px;
  box-shadow: var(--shadows-contained);
  display: flex;
  gap: 6px;
  height: 36px;
  justify-content: center;
  left: 1487px;
  padding: 6px 15px;
  position: absolute;
  top: 16px;
  width: 109px;
}

.products1 .button-text-3 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.products1 .element-button-text-2 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #7c3a8e;
  border-radius: 4px;
  box-shadow: var(--shadows-contained);
  display: flex;
  gap: 6px;
  height: 36px;
  justify-content: center;
  left: 1362px;
  padding: 6px 15px;
  position: absolute;
  top: 16px;
  width: 109px;
}

.products1 .button-text-4 {
  color: #7c3a8e;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.products1 .overlap-wrapper {
  height: 318px;
  left: 284px;
  position: absolute;
  top: 598px;
  width: 1618px;
}

.products1 .overlap-6 {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 318px;
  position: relative;
  width: 1616px;
}

.products1 .cards-header {
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #eeeeee;
  height: 52px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1616px;
}

.products1 .portlet-title {
  color: #000000;
  font-family: var(--h6-heading-font-family);
  font-size: var(--h6-heading-font-size);
  font-style: var(--h6-heading-font-style);
  font-weight: var(--h6-heading-font-weight);
  height: 21px;
  left: 16px;
  letter-spacing: var(--h6-heading-letter-spacing);
  line-height: var(--h6-heading-line-height);
  position: absolute;
  top: 15px;
  width: 654px;
}

.products1 .ic-expand-less {
  height: 24px;
  left: 1576px;
  position: absolute;
  top: 13px;
  width: 24px;
}

.products1 .table-header-6 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  left: 16px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 355px;
}

.products1 .cards-body {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 188px;
  left: 16px;
  position: absolute;
  top: 106px;
  width: 292px;
}

.products1 .group-4 {
  height: 76px;
  left: 99px;
  position: absolute;
  top: 51px;
  width: 76px;
}

.products1 .group-5 {
  height: 201px;
  left: 284px;
  position: absolute;
  top: 932px;
  width: 1618px;
}

.products1 .overlap-7 {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 201px;
  position: relative;
  width: 1616px;
}

.products1 .cards-body-2 {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 74px;
  left: 16px;
  position: absolute;
  top: 106px;
  width: 116px;
}

.products1 .overlap-group-4 {
  height: 37px;
  position: relative;
  top: 37px;
  width: 117px;
}

.products1 .group-6 {
  height: 26px;
  left: 46px;
  position: absolute;
  top: 0;
  width: 26px;
}

.products1 .table-header-wrapper {
  background-image: url(../img/vector.png);
  background-size: 100% 100%;
  height: 20px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 117px;
}

.products1 .table-header-7 {
  color: #757575;
  font-family: var(--overline-font-family);
  font-size: var(--overline-font-size);
  font-style: var(--overline-font-style);
  font-weight: var(--overline-font-weight);
  height: 14px;
  left: 8px;
  letter-spacing: var(--overline-letter-spacing);
  line-height: var(--overline-line-height);
  position: absolute;
  text-align: center;
  top: 2px;
  white-space: nowrap;
  width: 101px;
}

.products1 .group-7 {
  height: 270px;
  left: 284px;
  position: absolute;
  top: 304px;
  width: 1628px;
}

.products1 .overlap-8 {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #3f3f4426, 0px 0px 0px #3f3f440d;
  height: 270px;
  position: relative;
  width: 1616px;
}

.products1 .feather-icon {
  height: 23px;
  left: 1083px;
  position: absolute;
  top: 31px;
  width: 24px;
}

.products1 .feather-icon-type {
  height: 24px;
  left: 16px;
  position: absolute;
  top: 31px;
  width: 24px;
}

.products1 .feather-icon-hash {
  height: 23px;
  left: 1084px;
  position: absolute;
  top: 154px;
  width: 24px;
}

.products1 .element {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 56px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 31px;
  white-space: nowrap;
  width: 260px;
}

.products1 .element-2 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 1123px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 31px;
  white-space: nowrap;
  width: 260px;
}

.products1 .element-3 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 598px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 31px;
  white-space: nowrap;
  width: 260px;
}

.products1 .feather-icon-command {
  height: 24px;
  left: 558px;
  position: absolute;
  top: 34px;
  width: 24px;
}

.products1 .element-4 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 1124px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 154px;
  white-space: nowrap;
  width: 260px;
}

.products1 .element-5 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 56px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 154px;
  white-space: nowrap;
  width: 260px;
}

.products1 .element-6 {
  color: #000000;
  font-family: var(--subtitle1-dedisse-sc-font-family);
  font-size: var(--subtitle1-dedisse-sc-font-size);
  font-style: var(--subtitle1-dedisse-sc-font-style);
  font-weight: var(--subtitle1-dedisse-sc-font-weight);
  left: 598px;
  letter-spacing: var(--subtitle1-dedisse-sc-letter-spacing);
  line-height: var(--subtitle1-dedisse-sc-line-height);
  position: absolute;
  top: 154px;
  white-space: nowrap;
  width: 260px;
}

.products1 .feather-icon-type-2 {
  height: 24px;
  left: 16px;
  position: absolute;
  top: 154px;
  width: 24px;
}

.products1 .feather-icon-type-3 {
  height: 24px;
  left: 558px;
  position: absolute;
  top: 154px;
  width: 24px;
}

.products1 .line {
  height: 98px;
  left: 1067px;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 1px;
}

.products1 .line-2 {
  height: 98px;
  left: 1067px;
  object-fit: cover;
  position: absolute;
  top: 157px;
  width: 1px;
}

.products1 .line-3 {
  height: 98px;
  left: 542px;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 1px;
}

.products1 .line-4 {
  height: 98px;
  left: 542px;
  object-fit: cover;
  position: absolute;
  top: 157px;
  width: 1px;
}

.products1 .line-5 {
  height: 98px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 1px;
}

.products1 .line-6 {
  height: 1px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  top: 134px;
  width: 1577px;
}

.products1 .group-8 {
  height: 36px;
  left: 16px;
  position: absolute;
  top: 71px;
  width: 285px;
}

.products1 .overlap-group-5 {
  height: 36px;
  position: relative;
  width: 283px;
}

.products1 .element-7 {
  color: #9e9e9e;
  font-family: var(--body1-dedisse-scrips-copy-font-family);
  font-size: var(--body1-dedisse-scrips-copy-font-size);
  font-style: var(--body1-dedisse-scrips-copy-font-style);
  font-weight: var(--body1-dedisse-scrips-copy-font-weight);
  left: 16px;
  letter-spacing: var(--body1-dedisse-scrips-copy-letter-spacing);
  line-height: var(--body1-dedisse-scrips-copy-line-height);
  position: absolute;
  top: 8px;
  width: 216px;
}

.products1 .container {
  height: 36px;
  left: 0;
  position: absolute;
  top: 0;
  width: 283px;
}

.products1 .group-9 {
  height: 36px;
  left: 16px;
  position: absolute;
  top: 194px;
  width: 285px;
}

.products1 .feather-icon-chevron-4 {
  height: 24px;
  left: 252px;
  position: absolute;
  top: 6px;
  width: 24px;
}

.products1 .group-10 {
  height: 36px;
  left: 558px;
  position: absolute;
  top: 194px;
  width: 285px;
}

.products1 .group-11 {
  height: 36px;
  left: 1126px;
  position: absolute;
  top: 194px;
  width: 285px;
}

.products1 .group-12 {
  height: 36px;
  left: 558px;
  position: absolute;
  top: 71px;
  width: 285px;
}

.products1 .group-13 {
  height: 36px;
  left: 1083px;
  position: absolute;
  top: 71px;
  width: 285px;
}
.products1 a{
    test-decoration: none;
}
.element-button-text{
  cursor: pointer;
}
.products1 .img-wrapper {
  background-color: #4f2564;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}